/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:host {
    --background: var(--ion-background-color, #F1F5F9);
}

* {
    --background: var(--ion-background-color, #F1F5F9);
}

.modal-wrapper {
    min-width: 90vw !important;
    min-height: 80vh !important;
}

mat-calendar {
    background-color: white;
    color: black;
    border-radius: 2rem !important;
}

.mat-calendar-body-selected {
    background-color: #5ba74d !important;
}

.mat-calendar-body-cell-content:hover {
    background-color: #87ffb0 !important;
}

.mat-calendar-body-disabled {
    display: none !important;
}

mat-radio-button {
    :not(.mat-radio-checked) {
        .mat-radio-outer-circle {
            background-color: #ffffff;
        }
    }
}

.mat-radio-checked {
    .mat-radio-outer-circle {
        background-color: #5ba74d !important;
    }
}

.my-custom-class {
    .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}


.scrolling {
    z-index: 100;
}

.mat-button-toggle {
    &.mat-button-toggle-checked {
        background-color: #5ba74d !important;
        color: white !important;
        font-weight: 500 !important;
    }
}

auth-sign-in {
    width: 100vw !important;
}

auth-forgot-password {
    width: 100vw !important;
    height: 100vh !important;
}

auth-sign-up {
    width: 100vw !important;
    height: 100vh !important;
}

.mat-form-field-flex {
    background-color: white !important;
}

.mat-autocomplete-panel {
    background-color: white !important;
    color: black !important;

    .mat-option:hover {
        cursor: pointer !important;
        background-color: #D1FAE5 !important;
    }

    .mat-active {
        cursor: pointer !important;
        background-color: #D1FAE5 !important;
    }
}


.mat-select-panel {
    background-color: white !important;
    color: black !important;

    .mat-option:hover {
        cursor: pointer !important;
        background-color: #D1FAE5 !important;
    }
}

ion-toggle {
    --background: #9b9b9b;
    --background-checked: #9ad591;
  
    --handle-background: #ffffff;
    --handle-background-checked: #5ba74d;
}

.mat-button-disabled {
    opacity: 40% !important;
}